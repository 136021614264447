import golainLogo from "./assets/golain-full-light.svg";
import "./App.css";
import { useEffect, useState } from "react";

function App() {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_LINKS_URL}`)
      .then((res) => res.json())
      .then((data) => {
        setLinks(data["links"]);
      });
    return () => {};
  }, []);

  return (
    <div className="min-w-[100vw] min-h-[100vh] bg-[#7F12B2] flex flex-col items-center">
      <header className="max-w-md text-center">
        <img src={golainLogo} className="mt-20 px-4" alt="golain-logo" />
        <span className="block mt-4 text-gray-300">
          Find everything you need here!
        </span>
      </header>
      <section className="mt-12 pb-12 w-full flex flex-col gap-6 items-center p-4">
        {links.map((link) => (
          <a
            key={link.url}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="golain_button py-6 text-xl md:text-xl lg:text-2xl w-full lg:min-w-80 cursor-pointer max-w-xl font-semibold flex flex-col justify-center items-center"
          >
            {link.heading}
            <span className="text-center text-xs font-normal ">
              {link.sub_heading}
            </span>
          </a>
        ))}
      </section>
    </div>
  );
}

export default App;
